





































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState, mapActions } from 'vuex'
import { Item, Stats } from '@/types'
import Button from 'primevue/button'
import Card from 'primevue/card'
import Dropdown from 'primevue/dropdown'
import InputNumber from 'primevue/inputnumber'
import ItemLevel from './ItemLevel.vue'
import StatDistributionCharts from './StatDistributionCharts.vue'
import _ from 'lodash'

@Component({
  methods: {
    ...mapActions({
      calculateLevels: 'calculateLevels',
      calculateManyLevelUps: 'calculateManyLevelUps',
      resetItem: 'resetItem'
    })
  },
  computed: {
    ...mapState({
      itemLevels: 'itemLevels',
    })
  },
  components: {
    Button,
    Card,
    Dropdown,
    InputNumber,
    ItemLevel,
    StatDistributionCharts
  }
})
export default class ItemLeveler extends Vue {
  itemLevels!: Array<Item>;
  calculateLevels!: (
    payload: { item: Item, startLevel: number, endLevel: number }
  ) => Promise<void>;
  calculateManyLevelUps!: (
    payload: { item: Item, startLevel: number, endLevel: number }
  ) => Promise<void>;
  resetItem!: () => Promise<void>;

  item: Item = {
    level: 1,
    stats: {} as Stats,
    maxStats: {} as Stats,
    maxOfMaxStats: {} as Stats
  };
  startLevel: number | null = 1;
  endLevel: number | null = 7;
  
  onCalculateClick(): void {
    this.resetItem().then(() => {
      if (this.startLevel != null && this.endLevel != null) {
        this.item.level = this.startLevel;
        this.item.maxStats = this.item.stats;
        this.item.maxOfMaxStats = this.item.stats;

        this.calculateLevels({
          item: _.cloneDeep(this.item),
          startLevel: this.startLevel,
          endLevel: this.endLevel
        });

        this.calculateManyLevelUps({
          item: _.cloneDeep(this.item),
          startLevel: this.startLevel,
          endLevel: this.endLevel
        });
      }
    });
  }
  
  get startLevelOptions(): Array<number> {
    return Array.from({ length: 10 }, (_element, index) => index + 1);
  }

  get endLevelOptions(): Array<number> {
    var endLevelOptions = [];
      if (this.startLevel != null) {  
        for (let i = this.startLevel + 1; i <= 11; i++) {
          endLevelOptions.push(i);
        }
      }
    return endLevelOptions;
  }

  @Watch('startLevel')
  onStartLevelChanged(newVal: number): void {
    if ((this.endLevel) && (this.endLevel <= newVal)) {
      switch (newVal) {
        case 1:
          this.endLevel = 7;
          break;
        case 7:
          this.endLevel = 10;
          break;
        case 10:
          this.endLevel = 11;
          break;
        default:
          this.endLevel = newVal + 1;
          break;
      }
    }
  }

  
}
