













import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { LevelUpResult } from '@/types'
import StatChart from './StatChart.vue'

@Component({
  computed: {
    ...mapState({
      levelUpResults: 'levelUpResults'
    })
  },
  components: {
    StatChart
  }
})
export default class StatDistributionCharts extends Vue {
  levelUpResults!: Array<LevelUpResult>;

  prettifyStatName(stat: string): string {
    switch (stat) {
      case 'wdef':
        return 'WDef';
      case 'mdef':
        return 'MDef';
      case 'acc':
        return 'Acc';
      case 'avoid':
        return 'Avoid';
      case 'speed':
        return 'Speed';
      case 'jump':
        return 'Jump';
      default:
        return stat.toUpperCase();
    }
  }
}
