






import { Component, Vue } from 'vue-property-decorator'
import ItemLeveler from '@/components/ItemLeveler.vue'

@Component({
  components: {
    ItemLeveler,
  },
})
export default class Home extends Vue {}
